import { isIOS } from '../helpers/helperFunctions';

export function bindFunctions(element: any, ...funcs: Array<(target: any, ...args: any[]) => void>) {
    return function (...args) {
        funcs.forEach((f, i) => f(element, args[i]));
        return element;
    }
}

export function createElement(selector: string): HTMLElement {
    return document.createElement(selector);
}

export function createAnchor(href: string = '', text: string = '', className: string = ''): HTMLAnchorElement {
    return bindFunctions(
        createElement('a'),
        addHref,
        addTextContent,
        addClassName
    )(href, text, className);
}

export function createSpan(className: string): HTMLElement {
    return addClassName(createElement('span'), className);
};

export function createDiv(className: string): HTMLElement {
    return addClassName(createElement('div'), className);
};

export function createNav(className: string): HTMLElement {
    return addClassName(createElement('nav'), className);
};

export function createUList(className: string): HTMLElement {
    return addClassName(createElement('ul'), className);
};

export function createListItem(className: string): HTMLElement {
    return addClassName(createElement('li'), className);
};

export function createButton(className: string): HTMLElement {
    return addClassName(createElement('button'), className);
};

export function createElementFromString(str: string): HTMLElement {
    const wrapper = createDiv('');
    wrapper.innerHTML = str;
    return <HTMLElement>wrapper.firstElementChild || wrapper;
}

export function createSVG(inner: string): HTMLElement {
    const svg = document.createElement('svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('viewBox', '0 0 86 86');
    svg.innerHTML = inner;

    return svg;
}

export function addTextContent(element: HTMLElement, value: string): HTMLElement {
    element.textContent = value;
    return element;
}

export function addInnerHTML(element: HTMLElement, value: string): HTMLElement {
    element.innerHTML = value;
    return element;
}

export function addClassName(node: HTMLElement, className: string): HTMLElement {
    node.className = className;
    return node;
}

export function addClass(node: HTMLElement, className: string): HTMLElement {
    if (className) node.classList.add(className);
    return node;
}

export function addClasses(node: HTMLElement, ...classes: string[]): HTMLElement {
    classes.forEach(cls => addClass(node, cls));
    return node;
}

export function removeClass(node: HTMLElement, className: string): HTMLElement {
    if (className) node.classList.remove(className);
    return node;
}

export function removeClasses(node: HTMLElement, ...classes: string[]): HTMLElement {
    classes.forEach(cls => removeClass(node, cls));
    return node;
}

export function addClassToList(list: Element[] | HTMLElement[] | HTMLCollection | NodeList, className: string) {
    Array.from(list).forEach(itm => addClass(<HTMLElement>itm, className));
    return list;
}

export function removeClassFromList(list: Element[] | HTMLElement[] | HTMLCollection | NodeList, className: string) {
    Array.from(list).forEach(itm => removeClass(<HTMLElement>itm, className));
    return list;
}

export function hasClass(
    element: HTMLElement | Element | EventTarget,
    value: string
): boolean {
    return (<HTMLElement>element)?.classList.contains(value);
}

export function addHref(element: HTMLAnchorElement, value: string): HTMLAnchorElement {
    element.href = value;
    return element;
}

export function addToElement(element: HTMLElement, ...items: HTMLElement[]): HTMLElement {
    items.filter(i => i).forEach(i => element.appendChild(i));
    return element;
}

export function addToElementAtTheBeginning(element: HTMLElement, ...items: HTMLElement[]): HTMLElement {
    const children = <HTMLElement[]>Array.from(element.children);
    return addToElement(element, ...items.concat(children));
}

export function addElementsFromArray(target: HTMLElement, arr: HTMLElement[]) {
    arr.forEach(e => addToElement(target, e));
    return arr;
}

export function getElementHeight(element: HTMLElement): number {
    return element.offsetHeight;
}

export function setElementHeight(element: HTMLElement, height: number) {
    element.style.height = height ? height + 'px' : '';
    return this;
}

export function isInViewPort(element: HTMLElement): boolean {
    const elementRect = element.getBoundingClientRect(),
        offsetHeight = document.documentElement.offsetHeight;

    return elementRect.top < 0 && elementRect.bottom > 0
        || elementRect.top >= 0 && elementRect.top < offsetHeight
        || elementRect.bottom > 0 && elementRect.bottom < offsetHeight;
}

export function observeFirstTimeInViewport(element): Promise<boolean> {
    return new Promise((resolve, reject) => {
        const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
            resolve(true);
            observer.disconnect();
            }
        });
        }, { threshold: 0.01 });

        observer.observe(element);
    });
}
  

export function getSibling(element: HTMLElement, selector: string): HTMLElement {
    return <HTMLElement>element.parentElement.querySelector(selector);
}

export function getClosest(element: HTMLElement, selector: string): HTMLElement {
    do {
        if (element.matches(selector)) {
            return element;
        }
        element = <HTMLElement>element.parentElement || <HTMLElement>element.parentNode;
    } while (element !== null && element.nodeType === 1);

    return null;
}

export function createScriptTag(script: HTMLScriptElement): HTMLScriptElement {
    let domScript = document.createElement('script');
    domScript.type = script.type;

    if (script.src) {
        domScript.src = script.src;
    } else {
        domScript.textContent = script.innerText;
    }

    if (script.defer) {
        domScript.defer = true;
    }

    return domScript;
}

export function convertToPlain(html): string {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;

    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export function injectScriptsFromAjaxResponse(element: HTMLElement): Promise<any[]> {

    const scripts = Array.from(element.getElementsByTagName('script'))
        .filter(script => !isScriptTagRendered(script))
        .map(createScriptTag);

    return Promise.all(scripts.map(script => new Promise<void>((resolve, reject) => {
        if (script.src) {
            script.onload = () => resolve();
            script.onerror = () => {
                console.error(`Layer script was not loaded: URL ${(<HTMLScriptElement>event.target).src}`);
                resolve();
            };
        } else {
            resolve();
        }

        document.head.appendChild(script);
    })));
}

export function isScriptTagRendered(script: HTMLScriptElement): boolean {
    let exist: boolean = false;
    const shouldScriptNotBeMoved = script.hasAttribute('data-dont-move-script-from-layer');

    if (script.src) {
        (document.querySelectorAll(`[src="${script.src}"]`).length > 0 && script.src.indexOf("recaptcha") == -1) ? exist = true : exist = false;
    }

    if (shouldScriptNotBeMoved) {
        exist = true;
    }

    return exist;
}

export function toggleClass(node: HTMLElement, className: string, force?: boolean): HTMLElement {
    if (typeof force === undefined) {
        node.classList.toggle(className);
    } else {
        force
            ? node.classList.add(className)
            : node.classList.remove(className);
    }

    return node;
}

export function getChildren(node: HTMLElement, selector?: string): HTMLElement[] {
    let children = <HTMLElement[]>Array.from(node.children);

    if (selector) {
        children = children.filter(ch => hasClass(ch, selector));
    }

    return children;
}

export function dispatchEvent(element: HTMLElement, event: Event): HTMLElement {
    element.dispatchEvent(event);
    return element;
}

/*
 *
 * Helper Functions for Video Movie
 *
 */

export function hasVideoToBeLoaded(video: HTMLVideoElement): boolean {
    return !isVideoLoaded(video) && isInViewPort(video) && document.readyState == "complete";
}

export function hasVideoToBePaused(video: HTMLVideoElement) {
    return isVideoLoaded(video) && !isInViewPort(video) && isVideoPlaying(video);
}

export function hasVideoToBePlay(video: HTMLVideoElement): boolean {
    return isVideoLoaded(video) && isInViewPort(video) && !isVideoPlaying(video) && isVideoAutoplay(video);
}

export function isVideoAutoplay(video) {
    return video.getAttributeNames()?.includes('autoplay');
}

export function isVideoLoaded(video: HTMLVideoElement): boolean {
    return (video.hasAttribute('src') || video.readyState == 4);
}

export function playVideo(video: HTMLVideoElement) {
    return video.play();
}

export function pauseMovieVideo(vid: HTMLVideoElement) {
    return vid.pause();
}

export function showVideo(video: HTMLVideoElement) {
    video.parentElement.classList.add("nx-movie-video--ready");
}

export function setVideoSourceAttr(video: HTMLVideoElement): HTMLVideoElement {
    let src;

    if (window.innerWidth >= 768) {
        src = video.dataset.srcCompact || video.dataset.src || video.dataset.srcDefault;
    } else {
        src = video.dataset.srcCompactmobile || video.dataset.srcMobile || video.dataset.srcDefault;
    }

    const currentSrc = video.getAttribute('src');
    if (src && src !== currentSrc)
        video.setAttribute('src', src);

    return video;
}

export function removeDataVideoAttr(video: HTMLVideoElement): HTMLVideoElement {
    video.removeAttribute('data-src');

    return video;
}

export function isVideoPlaying(vid): boolean {
    const video: HTMLVideoElement = <HTMLVideoElement>vid;
    return !!(video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
}

export function getContent(node: HTMLElement | string): string {
    const element = typeof node === 'string' ? createElementFromString(node) : node;
    return element.innerHTML;
}

export function isImageLoaded(img: HTMLImageElement): boolean {
    return (img.hasAttribute('src') || img.hasAttribute('srcset'))
        || (!isIOS && img.complete && img.naturalHeight !== 0);
}

export function showSpinner(img: HTMLImageElement) {
    addClass(img.parentElement, 'nx-u-spinner');
    return img;
}

export function hideSpinner(img: HTMLImageElement) {
    removeClass(img.parentElement, 'nx-u-spinner');
    return img;
}

export function clearElement(e: HTMLElement): HTMLElement {
    e.innerHTML = '';
    return e;
}

export function clearStyleElement(e: HTMLElement): HTMLElement {
    e.removeAttribute('style');
    return e;
}

export function adjustLayerMarkUp(html: string, overlayTitle?): HTMLElement {
    let node = createElementFromString(`<div>${html}</div>`),
        layerTitle = (overlayTitle) ? overlayTitle : '';

    return createElementFromString(`
        <div class="nx-content__wrapper">
            ${overlayTitle ?
            `<div class="nx-overlay__header">
                <h2 class="nx-overlay__title nx-font-heading-3 nx-font--extrabold nx-u-pt-base-5-desktop nx-u-pb-base-3-desktop nx-txt-uppercase">${layerTitle}</h2>
            </div>`
            : ""}
            <div class="nx-overlay__content">${node.innerHTML}</div>
        </div>`);
}

export function setFocusIntoLayer(element:HTMLElement): void {
    if (window.NiveaX.deviceType.isMobile == true || element.classList.contains('nx-spush-item')) {
        return;
    }
    
    const focusableElem = element.querySelectorAll('button, [href], input:not([type="hidden"]), select, textarea, [tabindex]:not([tabindex="-1"])'),
        firstFocusableElm = focusableElem[0] as HTMLElement;
        firstFocusableElm?.classList.contains('nx-shop-cart__promotion-form-input') ?
            firstFocusableElm?.parentElement.classList.add('nx-shop-cart__promotion-form-input--focus') : "";

        firstFocusableElm?.focus();
}

export function objectToStyleString(styleObject): string {
    return Object.entries(styleObject).map(([k, v]) => `${k}: ${v}`).join(';');
}

export function isChildOf(element: HTMLElement, parent: HTMLElement): boolean {
    do {
        if (element === parent) return true;
        element = element.parentElement;
    } while (element);

    return false;
}


export function numDigits(x: number): number {
    return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
}

export function addSpinnerToTeaserGrid(gridNode: HTMLElement) {
    addClass(gridNode, "nx-teaser-grid--loading");

    gridNode.insertBefore(createElementFromString(
        `<div class="nx-spinner nx-spinner--overlay">
            <div class="nx-spinner__container">
                <div class="nx-spinner__wheel"></div>
            </div>
        </div>`), gridNode.firstChild || null);
}

export function removeSpinnerFromTeaserGrid(gridNode: HTMLElement) {
    removeClass(gridNode, "nx-teaser-grid--loading");
}
