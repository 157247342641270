import { deepmerge } from 'deepmerge-ts';
import { getSibling, addClass, addClasses, removeClass, removeClasses } from './DOMHelpers';

export function copyObject(options) {
    return JSON.parse(JSON.stringify(options));
}

/**
 * Copies current URL query parameters to passed URL.
 * This is needed for instance for a layer.
 * Parameter will not be copied if the parameter name is already included in the URL or if the parameter name is included as an element in parametersToOmit.
 */
export function copyQueryParametersToUrl(url: string, parametersToOmit: string[] = []) {
    const parameters = window.location.search.substring(1);
    if (!parameters) return url;
    const parametersToCopy = decodeURIComponent(parameters)
        .split("&")
        .filter(parameter => {
            const parameterKey = parameter.split("=")[0];
            return url.indexOf(`${parameterKey}=`) < 0 && !parametersToOmit.includes(parameterKey);
        })
        .join("&");
    if (!parametersToCopy) return url;
    return url.indexOf("?") <= 0
        ? `${url}?${parametersToCopy}`
        : `${url}&${parametersToCopy}`;
}

export function getOptions(element: HTMLElement, defOptions: any = {}): any {
    let optionsStringFromData = element.dataset.options,
        optionsFromData;
        
        if (optionsStringFromData) {
            try {
                optionsFromData = JSON.parse(optionsStringFromData);
            } catch (e) {
                console.warn("Bad data-options for: " + element + " " + element.dataset.options);
        }
    }

    return typeof optionsFromData === 'object'
        ? deepmerge(defOptions, optionsFromData)
        : defOptions;
}

export function loadParamsFromURL(url?: string) {
    const historyParameter = url || location.search;
    let state = {};
    if (historyParameter !== '' && historyParameter !== undefined) {
        const searchParameters = new URLSearchParams(historyParameter) as any;
        state = Object.fromEntries(searchParameters);
    }

    return state;
}

// Filter helper functions
export function toggleSelection(input: HTMLInputElement, select: boolean = true): void {
    input.checked = select;
}

export function getNamesOfInputs(inputs: FilterCheckBox[]): string[] {
    return inputs.sort(compareTimeStamp).map(getFilterName);
}

export function compareTimeStamp(a: FilterCheckBox, b: FilterCheckBox): number {
    return a.timeStamp > b.timeStamp ? 1 : -1;
}

export function getFilterName(element: HTMLElement): string {
    const node = element.nextElementSibling;
    return node ? node.textContent.trim() : '';
}

export function getCateforyName(nameNodeSelector: string, element: HTMLElement): string {
    const nameNode = getSibling(element, nameNodeSelector);
    return nameNode ? nameNode.textContent : '';
}

export function getPageScrollTop(): number {
    return document.documentElement.scrollTop || document.body.scrollTop;
}

export const getScrollTopPosition = (scrollTop: number): number => document.documentElement.scrollHeight * scrollTop;

export const isViewPortInPosition = (scrollTop: number): boolean => getPageScrollTop() + document.documentElement.offsetHeight >= getScrollTopPosition(scrollTop);

export function setPageScrollTop(value: number): void {
    document.documentElement.scrollTop = document.body.scrollTop = value;
}



// horizontalScrolling
export function horizontalScrolling(element: HTMLElement) {
    let x: number,
        left: number,
        leftUp: number,
        down: boolean;

    function onMouseDown(event: MouseEvent): void {
        event.preventDefault();
        down = true;
        x = event.pageX;
        left = element.scrollLeft;

        document.body.addEventListener('mousemove', onBodyMouseMove);
        document.body.addEventListener('mouseup', onBodyMouseUp);
    }

    function onBodyMouseMove(event: MouseEvent): void {
        if (down) {
            const newX = event.pageX;
            element.scrollLeft = left - newX + x;
        }
    }

    function onBodyMouseUp(): void {
        document.body.removeEventListener('mousemove', onBodyMouseMove);
        document.body.removeEventListener('mouseup', onBodyMouseUp);
        down = false;
        leftUp = element.scrollLeft;

        if (left !== leftUp) window.addEventListener('click', captureClick, false);
    }

    function captureClick(event: MouseEvent): void {
        event.stopPropagation();
        event.stopImmediatePropagation();
        event.preventDefault();
        // console.log(event.type, event.target)
        window.removeEventListener('click', captureClick, false);
    }

    element.addEventListener('mousedown', onMouseDown);
}

// Cookie methods
export function setCookie(cname: string, cvalue: string, exdays?: number) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = exdays ? 'expires=' + d.toUTCString() : '';

    document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=/';
}

export function deleteCookie(name: string) {
    document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function getCookie(cname: string): string {
    var name = cname + '=',
        ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) !== -1) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

// for Cookies
const getCookiesArray = () => document.cookie.split('; ');

export function getCookieValue(name: string): string {
    const cookies = getCookiesArray();

    return cookies
        .filter(c => c.startsWith(name))
        .map(c => c.split('=')[1])
    [0];
};

export function checkCookie(name: string, checkFunc: CheckFunc = a => true) {
    const cookieValue = getCookie(name);
    return checkFunc(cookieValue);
};

// IOS prevent scrolling
export class IOSScroll {
    static prevent(visual: boolean = false, preventClass: string = 'nx-u-prevent-scroll'): void {
        if (!isIOS()) {
            document.body['preventLevel'] = document.body['preventLevel'] + 1 || 1;
            addClass(document.body, 'nx-u-lock-screen');
            return;
        }

        const scrollTop = getPageScrollTop();

        IOSScroll.scrollTop.unshift(IOSScroll.scrollTop.length ? IOSScroll.scrollTop[0] : scrollTop);
        document.body.classList.add(preventClass);
        setTimeout(() =>
            IOSScroll.setNegativeMarginTop(
                visual ? IOSScroll.scrollTop[0] : null
            ), IOSScroll.scrollTop.length > 1 ? 500 : 0);
    }

    static allow(visual: boolean = false, preventClass: string = 'nx-u-prevent-scroll'): void {
        if (!isIOS()) {
            document.body['preventLevel'] = Math.max(document.body['preventLevel'] - 1, 0);
            document.body['preventLevel'] === 0 && removeClass(document.body, 'nx-u-lock-screen');
            return;
        }

        const firstScrollTop = IOSScroll.scrollTop.shift();
        IOSScroll.setNegativeMarginTop(IOSScroll.scrollTop[0] || null);

        if (IOSScroll.scrollTop.length === 0) {
            document.body.classList.remove(preventClass);

            const hash: string = window.location.hash.substring(1);
            if (hash && hash.indexOf('layer') === -1 && document.getElementById(`${hash}`)) {
                document.getElementById(`${hash}`).scrollIntoView();
            } else {
                setPageScrollTop(firstScrollTop)
            }
        }
    }

    private static setNegativeMarginTop(value: number): void {
        document.body.style.marginTop = value && `-${value}px`;
    }

    static scrollTop: number[] = [];
}

export function isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];
}

export function isIE11(): boolean {
    return !!navigator.userAgent.match(/Trident.*rv\:11\./);
}

export function isPageEditor(): boolean{
    return window.location.search.includes('view=page-editor') || window.location.search.includes('view=content-editor');
}

export function focusInput(input: HTMLInputElement): HTMLInputElement {
    let position = input.value.length;

    if (isIE11() && position) {
        input.setSelectionRange(position, position);
    }

    input.focus();

    return input;
}

export function parseJSONSafe(json: string): any {
    let result = null;

    if (json) {
        try {
            result = JSON.parse(json);
        } catch (e) {
            console.warn(e.message, json);
        }
    }

    return result;
}

export function cleanString(str: string): string {
    return str.replace(/[^\w\W\s\u00C0-\u00FF]/gi, '');
}

export function removeFromArray(arr: any[], element: any) {
    arr.splice(arr.indexOf(element), 1);
};

export function requestNextAnimationFrame(func: () => any): number {
    return requestAnimationFrame(requestAnimationFrame.bind(null, func));
}

export function requestAnimationFrameTimer(func: () => any, time: number = 0): void {
    requestAnimationFrame(
        () => setTimeout(
            () => func(),
            time)
    );
}

export function limitCall(func, delay: number) {
    let timerId;

    return function (...args) {
        if (timerId) return;
        timerId = setTimeout(() => timerId = null, delay);
        func.apply(this, args);
    }
}

export function getLastItemInArray(arr: any[]): any {
    const length = arr.length;
    return length > 0 ? arr[arr.length - 1] : arr[0];
}

export const isBoolean = (value: any): boolean => typeof value === 'boolean';
export const isNumber = (value: any): boolean => typeof value === 'number';
export const isString = (value: any): boolean => typeof value === 'string';

export function getURLParamsArray(): string[] {
    return window.location.search.substring(1).split('&');
}

export const getMillisecondsOfSeconds = (seconds: number = 0): number => seconds * 1000;

export const getMillisecondsOfMinutes = (minutes: number = 0): number => getMillisecondsOfSeconds(minutes * 60);

export const getMillisecondsOfHours = (hours: number = 0): number => getMillisecondsOfMinutes(hours * 60);

export const getMillisecondsOfDays = (days: number = 0): number => getMillisecondsOfHours(days * 24);

export const isNotUndefined = (...values) => values.every(v => v != undefined);

export function replaceQueryParam(param, newval, search, remove = '&') {
    var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
    var query = search.replace(regex, "$1").replace(/&$/, '');

    return (query.length > 2 ? query + remove : "?") + (newval ? param + "=" + newval : '');
}

export function removeQueryParam(key, sourceURL) {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}

export const isObjectEmpty = obj => Object.entries(obj).length === 0 && obj.constructor === Object;

export const isArrayEmpty = arr => Array.isArray(arr) && arr.length > 0;

export const getCurrentPageInfo = (): string => `${document.title} - ${location.origin}${location.pathname}`;

export const getAjaxURLs = (): AjaxURLs => {
    const configNode = document.querySelector('[data-ajax-urls]');
    const ajaxUrls: AjaxURLs = configNode && JSON.parse(configNode.innerHTML) || {};
    return ajaxUrls;
}

export const checkAndInitShop = async () => {
    const {
        Context = '',
        FeatureAjaxUrls: {
            Shop: shopUrls,
        },
    } = getAjaxURLs();

    if (shopUrls) {
        const module = await import(/* webpackChunkName: "ShopInitializer" */'../ShopInitializer');
        const initShop = module.default;
        initShop(Context, shopUrls);
    };
}

export const getServiceLayerUrl = (hash?: string) => {
    const layerName = location.hash.split('=')[1] || hash.split('=')[1];
    const currentUrl = encodeURIComponent(window.location.href.split("#")[0]);
    const contextItem = window.location.href.split('~').pop();
    const {
        FeatureAjaxUrls: {
            Layer: ResolveLayer,
        },
    } = getAjaxURLs();

    let url = ResolveLayer.ResolveLayerUrl;
    url = url.replace(/{layerName}/gi, layerName);
    url = url.replace(/{currentUrl}/gi, currentUrl);
    if (contextItem) url.replace(/{contextItem}/gi, contextItem);

    return {
        Url: url,
        PostUrl: ResolveLayer.ResolveLayerPostUrl
    };
}

export const getCartOverviewLayerUrl = () => {
    const {
        FeatureAjaxUrls: {
            Shop: Shop,
        },
    } = getAjaxURLs();

    return Shop.CartOverviewPage;
}

export const constructServiceLayerRequest = (url?: string) => {
    const layerName = location.hash.split('=')[1];
    const currentUrl = window.location.href.split("#")[0];

    let parameters = loadParamsFromURL(url);

    return {
        Layer: layerName,
        CurrentUrl: currentUrl,
        CurrentPage: parameters["currentpage"]
    }
}

export function getSizes(element: HTMLElement): ClientRect {
    const clientRect = (): ClientRect => element.getBoundingClientRect();

    return {
        get height(): number {
            return clientRect().height;
        },

        get width(): number {
            return clientRect().width;
        },

        get top(): number {
            return clientRect().top;
        },

        get bottom(): number {
            return clientRect().bottom;
        },

        get left(): number {
            return clientRect().left;
        },

        get right(): number {
            return clientRect().right;
        },

        get x(): number {
            return clientRect().x;
        },

        get y(): number {
            return clientRect().y;
        },

        toJSON(): any {
            return {
                height: this.height,
                width: this.width,
                top: this.top,
                bottom: this.bottom,
                left: this.left,
                right: this.right,
                x: this.x,
                y: this.y
            };
        }
    };
}

export function debounce(func, wait: number) {
    let timerId;

    return function (...args) {
        if (timerId) {
            clearTimeout(timerId);
            timerId = null;
        }

        timerId = setTimeout(() => {
            func.apply(this, args);
            timerId = null;
        }, wait);
    }
}

export function floatNumberFromString(element: any) {
    const regExpForFloatNumbers = /\d+(?:.\d+)?/;
    return element.match(regExpForFloatNumbers).join('');
}

// object = {main1:{inner11:value11, inner21:value21}, main2{inner21:value21, inner22:value22, inner32:value32}}
// parseObjectByPath(object, 'main1.inner11') // value11
// parseObjectByPath(object, 'main2.inner22') // value22
export function parseObjectByPath(object: object, path: string) {
    return path.split('.').reduce((oldValue, newValue) => {
        return oldValue ? oldValue[newValue] : null
    }, object || self)
}

export function isSelectorValid(selector) {
    const queryCheck = s => document.createDocumentFragment().querySelector(s)
    try { queryCheck(selector) } catch { return false }
    return true
}

export function startSpinner() {
    const item: HTMLElement = document.body.querySelector('main'),
        extraClass: string = 'nx-u-spinner--fixed';

    addClasses(item, 'nx-u-spinner', 'nx-u-spinner--medium', extraClass);
}

export function stopSpinner() {
    const item: HTMLElement = document.body.querySelector('main'),
        extraClass: string = 'nx-u-spinner--fixed';

    removeClasses(item, 'nx-u-spinner', 'nx-u-spinner--medium', extraClass);
}

export function checkMobileOrDesktop() {
    return window.innerWidth < 768;
}

export function checkMobileTabletOrDesktop() {
    return window.innerWidth < 1024;
}

export function getYouTubeVideoIdFromUrl(url) {
    var regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|shorts\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    return (match && match[2].length >= 11) ? match[2] : '';
}